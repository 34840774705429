import { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import DocxUpload from "./DocxUpload";
import CSVUpload from "./CSVUpload";
import AuthButtons from "./AuthButtons";
import { useAuth0 } from "@auth0/auth0-react";
import md5 from "md5";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { Logo } from "../homepageComponents/Logo"; // Adjust the path as necessary

const navigation = [
  { name: "Home", href: "/", icon: HomeIcon, current: true },
  { name: "Profile", href: "/profile", icon: UsersIcon, current: false },
  { name: "Dashboard", href: "/dashboard", icon: FolderIcon, current: false },
  // { name: "Calendar", href: "#", icon: CalendarIcon, current: false },
  // { name: "Documents", href: "#", icon: DocumentDuplicateIcon, current: false },
  // { name: "Reports", href: "#", icon: ChartPieIcon, current: false },
];
const teams = [
  { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
  { id: 2, name: "Tailwind Labs", href: "#", initial: "T", current: false },
  { id: 3, name: "Workcation", href: "#", initial: "W", current: false },
];
const userNavigation = [{ name: "Your profile", href: "/profile" }];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function getGravatarUrl(email: string): string {
  // Explicitly type email as a string
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
}

const serverUrl = process.env.REACT_APP_SERVER_URL;

const Profile: React.FC = () => {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [gmailUsername, setGmailUsername] = useState(user?.gmailUsername || "");
  const [gmailPassword, setGmailPassword] = useState(user?.gmailPassword || "");
  const [emailTemplateContent, setEmailTemplateContent] = useState(
    user?.emailTemplateContent || ""
  );
  const [exampleEmailContent, setExampleEmailContent] = useState(
    user?.exampleEmailContent || ""
  );

  const [emailTitleTemplateContent, setEmailTitleTemplateContent] = useState(
    user?.emailTitleTemplateContent || ""
  );
  const [customPrompt, setCustomPrompt] = useState(user?.customPrompt || "");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      setName(user?.name || "");
      setEmail(user?.email || "");
      setGmailUsername(user?.gmailUsername || "");
      setGmailPassword(user?.gmailPassword || "");
      setCustomPrompt(user?.customPrompt || "");
      setEmailTitleTemplateContent(user?.emailTitleTemplateContent || "");
    }
  }, [isAuthenticated, user]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const gravatarUrl = user?.email ? getGravatarUrl(user.email) : "";

  const handleUpdateProfile = async () => {
    try {
      const token = await getAccessTokenSilently();

      // Ensure user is defined before accessing its properties
      if (user && user.sub) {
        const response = await axios.post(
          `${serverUrl}/update-profile`,
          {
            userId: user.sub, // Ensure this is provided
            name: name,
            email: email,
            gmailUsername: gmailUsername,
            gmailPassword: gmailPassword,
            emailTemplateContent: emailTemplateContent,
            exampleEmailContent: exampleEmailContent,
            customPrompt: customPrompt,
            emailTitleTemplateContent: emailTitleTemplateContent,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Update local state with the new profile data
        setName(response.data.user.name);
        setEmail(response.data.user.email);
        setGmailUsername(response.data.user.gmailUsername || "");
        setGmailPassword(response.data.user.gmailPassword || "");
        setEmailTemplateContent(response.data.user.emailTemplateContent || "");
        setExampleEmailContent(response.data.user.exampleEmailContent || "");
        setCustomPrompt(response.data.user.customPrompt || "");
        setEmailTitleTemplateContent(
          response.data.emailTitleTemplateContent || ""
        );

        setMessage("Profile updated successfully!");
      } else {
        console.error("User data is missing");
        setMessage("Error: User data is missing.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setMessage("Error updating profile.");
    }
  };

  return (
    <div>
      <Dialog
        open={sidebarOpen}
        onClose={setSidebarOpen}
        className="relative z-50 lg:hidden"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <TransitionChild>
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button
                  type="button"
                  onClick={() => setSidebarOpen(false)}
                  className="-m-2.5 p-2.5"
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-white"
                  />
                </button>
              </div>
            </TransitionChild>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
              <div className="flex h-16 shrink-0 items-center">
                <a href="/dashboard">
                  <Logo className="h-10 w-auto" />
                </a>{" "}
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" className="-mx-2 space-y-1">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-800 text-white"
                                : "text-gray-400 hover:bg-gray-800 hover:text-white",
                              "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                            )}
                          >
                            <item.icon
                              aria-hidden="true"
                              className="h-6 w-6 shrink-0"
                            />
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <div className="text-xs font-semiboldleading-6 text-gray-400">
                      Your Resume
                    </div>
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                      <DocxUpload />
                    </ul>
                  </li>
                  <li className="mt-auto">
                    <a
                      href="/profile"
                      className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                    >
                      <Cog6ToothIcon
                        aria-hidden="true"
                        className="h-6 w-6 shrink-0"
                      />
                      Settings
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <a href="/dashboard">
              <Logo className="h-10 w-auto" />
            </a>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-800 text-white"
                            : "text-gray-400 hover:bg-gray-800 hover:text-white",
                          "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                        )}
                      >
                        <item.icon
                          aria-hidden="true"
                          className="h-6 w-6 shrink-0"
                        />
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">
                  Your Resume
                </div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  <DocxUpload />
                </ul>
              </li>
              <li className="mt-auto">
                <a
                  href="/profile"
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                >
                  <Cog6ToothIcon
                    aria-hidden="true"
                    className="h-6 w-6 shrink-0"
                  />
                  Settings
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-72">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            onClick={() => setSidebarOpen(true)}
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>

          {/* Separator */}
          <div
            aria-hidden="true"
            className="h-6 w-px bg-gray-900/10 lg:hidden"
          />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <form action="#" method="GET" className="relative flex flex-1">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                aria-hidden="true"
                className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
              />
              <input
                id="search-field"
                name="search"
                type="search"
                placeholder="Search..."
                className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              />
            </form>
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon aria-hidden="true" className="h-6 w-6" />
              </button>

              {/* Separator */}
              <div
                aria-hidden="true"
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
              />

              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <MenuButton className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                  <img
                    alt={user?.name}
                    src={gravatarUrl}
                    className="h-8 w-8 rounded-full bg-gray-50"
                  />
                  <span className="hidden lg:flex lg:items-center">
                    <span
                      aria-hidden="true"
                      className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                    >
                      {user?.name}
                    </span>
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="ml-2 h-5 w-5 text-gray-400"
                    />
                  </span>
                </MenuButton>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  {userNavigation.map((item) => (
                    <MenuItem key={item.name}>
                      <a
                        href={item.href}
                        className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    </MenuItem>
                  ))}
                  <MenuItem>
                    <AuthButtons />
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
        </div>

        <main className="py-10">
          <div className="border-b border-gray-900/10 pb-12">
            <div className="">
              <div className="sm:col-span-3">
                {" "}
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Profile <br></br>
                  <br></br>
                  (You need to use Gmail App Password which is different from
                  your regular password.){" "}
                  <a
                    className="text-black font-bold"
                    href="https://www.youtube.com/watch?v=N_J3HCATA1c"
                    target="_blank"
                  >
                    Click to learn how to generate App Password
                  </a>
                </label>
                <div className="mt-4 p-4 bg-white rounded shadow-md">
                  <div className="mb-4">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Gmail E-mail Address
                    </label>
                    <input
                      type="text"
                      value={gmailUsername}
                      onChange={(e) => setGmailUsername(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="example@gmail.com"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                      Gmail *App* Password{" "}
                    </label>
                    <input
                      type="password"
                      value={gmailPassword}
                      onChange={(e) => setGmailPassword(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Your Gmail *APP* Password"
                    />
                  </div>
                  <button
                    onClick={handleUpdateProfile}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Save Profile
                  </button>
                  {message && <p className="mt-4">{message}</p>}
                </div>{" "}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Profile;
