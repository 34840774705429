import React from "react";

interface FileMetadata {
  _id: string;
  userId: string;
  filename: string;
  path: string;
  uploadedAt: string;
}

interface FileListProps {
  files: FileMetadata[];
  onFileClick: (filename: string) => void;
  onFileDelete: (filename: string) => void;
}

const FileList: React.FC<FileListProps> = ({
  files,
  onFileClick,
  onFileDelete,
}) => {
  return (
    <div className="my-4 p-4 bg-white rounded shadow-md">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">Your Files</h2>
      <ul className="space-y-2">
        {files.map((file) => (
          <li
            key={file._id}
            className="flex items-center justify-between p-2 bg-gray-50 rounded hover:bg-gray-100"
          >
            <button
              className="text-blue-600 hover:underline"
              onClick={() => onFileClick(file.filename)}
            >
              {file.filename}
            </button>
            <button
              className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-700"
              onClick={() => onFileDelete(file.filename)}
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileList;
