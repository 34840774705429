// src/helpers/shortenHelper.ts
import axios from "axios";
import { CSVData } from "./csvHelper";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export class ShortenHelper {
  static async shortenContent(
    row: CSVData,
    token: string
  ): Promise<{ shortenedContent: string } | null> {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!apiKey) {
      console.error("OpenAI API key not found.");
      return null;
    }

    try {
      const shortenContentPrompt = `I have an e-mail content. I want to shorten it. I want you to perform these actions:
      1- Keep the most important details.
      2- Remove unnecessary words, phrases, or sentences.
      3- Make it concise and clear.
      4- Do not add any explanation or intro or anything.
      5- Do not use any qoutation marks or titles, marks etc. Make it ready to send. I will immadiately send this email so I will not review it. Make it ready to send.
      This is my email content: ${row["Email Content"]}`;

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4-turbo",
          messages: [
            {
              role: "system",
              content: "You are a helpful assistant.",
            },
            {
              role: "user",
              content: shortenContentPrompt,
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      const shortenedContent = response.data.choices[0].message.content;
      return { shortenedContent };
    } catch (error) {
      console.error("Error calling OpenAI API for content shortening:", error);
      return null;
    }
  }
}
