// src/prompts/emailTemplates.ts
export const exampleEmailContent = `
### Example Email 1: Highlighting a Personal Connection to the Product

Hi [Founder’s Name],

I’ve been a user of [Startup Name]'s [Product/Feature] and have found it incredibly [helpful/innovative]. The way it [solves specific problem] is outstanding, and I'm amazed by your team's creativity.

My name is [Your Name], and I'm a [Your Position/Role] with a strong background in [related field/skill]. With [X years] of experience in [specific area], I've developed a keen understanding of [related expertise].

I believe my skills in [specific skill] could further enhance [Startup Name]'s offerings. For instance, at [Previous Company], I [specific achievement], which I think aligns well with your current initiatives.

Could we schedule a call next week to explore how I might contribute to your team?

Thank you for considering my application. I look forward to the opportunity to connect.

Best,  
[Your Name]  
[Your Contact Information]

---

### Example Email 2: Emphasizing Industry Knowledge and Enthusiasm


Hi [Founder’s Name],

As someone passionate about the [industry/sector], I've been following [Startup Name]'s journey closely. Your innovative approach to [specific challenge or market] is truly pioneering, and I admire your vision for the future.

I’m [Your Name], a [Your Position] with [X years] of experience in [related field]. I specialize in [specific skill/technology], and my work has consistently focused on [specific area], where I achieved [specific result/impact].

I am particularly interested in [specific aspect of the company's work] and believe my expertise in [related skill] could support your team’s goals. For example, at [Previous Company], I [specific achievement], which demonstrates my capability to [related benefit].

Would you be open to discussing how I could add value to [Startup Name]? I’d be thrilled to connect at your convenience.

Thank you for your time.

Best regards,  
[Your Name]  
[Your Contact Information]

---

### Example Email 3: Focusing on Problem-Solving and Innovation


Hi [Founder’s Name],

I’ve been impressed by how [Startup Name] tackles [specific problem/issue] with such innovative solutions. Your recent launch of [specific product/feature] is a game-changer in the industry.

I'm [Your Name], currently a [Your Position] specializing in [related field]. With [X years] of experience in [specific area], I've honed my skills in [relevant skill/technology], where I've contributed to projects like [specific project/achievement].

I'm eager to bring my problem-solving skills to [Startup Name], particularly in [specific area of need]. In my previous role at [Previous Company], I [specific contribution], which could be highly beneficial for [Startup Name].

Could we discuss potential opportunities for collaboration? I’m available for a call next week if that works for you.

Looking forward to your response.

Best,  
[Your Name]  
[Your Contact Information]

---

### Example Email 4: Showcasing Alignment with the Company's Mission


Hi [Founder’s Name],

I was inspired by [Startup Name]'s mission to [specific mission statement or goal]. Your commitment to [specific aspect] is something I deeply admire and resonate with.

I’m [Your Name], a [Your Position] with extensive experience in [related field/area]. My background includes [X years] in [specific skill], where I've been involved in projects that align with [Startup Name]'s goals, such as [specific project/achievement].

I am enthusiastic about the possibility of contributing to your team, particularly in [specific area]. My work at [Previous Company] on [specific initiative] has prepared me well to support [Startup Name]'s [specific objective].

Would you have some time next week for a brief call to explore how I can help advance your mission?

Thank you for considering my request. I look forward to the possibility of working together.

Warm regards,  
[Your Name]  
[Your Contact Information]



### Example Email 5:


Hi [Founder’s Name],

I’ve been following [Startup Name] for a while and am truly impressed by [specific product/achievement/innovation]. Your recent work on [specific aspect] really stands out in the industry.

My name is [Your Name], and I'm a [Your Current Position] with a passion for [relevant field/skill]. I have [X years] of experience in [specific area], particularly in [specific expertise]. 

I believe my experience in [specific skills] could be valuable to [Startup Name], especially in [specific area]. At [Previous Company], I [briefly describe relevant experience], and I’m eager to bring that expertise to your team.

I would love the opportunity to discuss how I can contribute to [Startup Name]'s exciting projects. Would you be available for a brief call next week?

Thank you for your time and consideration. Looking forward to your response.

Best regards,  
[Your Name]  
[Your Contact Information]
`;

export const emailTemplateContent = `

### Email Body Strucure:

1. - "Hi [Founder’s Name],"

2. - Begin with a compliment about the company and express your genuine interest.
   - Example: "I’ve been following [Startup Name] for a while and am truly impressed by [specific product/achievement/innovation]. Your recent work on [specific aspect] really stands out in the industry."

3. - Briefly introduce yourself and your relevant background.
   - Example: "My name is [Your Name], and I'm a [Your Current Position/Role] with a passion for [relevant field/skill]. I have [X years] of experience in [specific area], particularly in [specific expertise]."

4. - Highlight how your skills and experience align with the company’s goals and how you can contribute.
   - Example: "I believe my experience in [specific skills] could be valuable to [Startup Name], especially in [specific area]. At [Previous Company], I [briefly describe relevant experience], and I’m eager to bring that expertise to your team."

5. - Suggest a follow-up, such as a meeting or call.
   - Example: "I would love the opportunity to discuss how I can contribute to [Startup Name]'s exciting projects. Would you be available for a brief call next week?"

6. - End with a polite closing and your contact information.
   - Example: "Thank you for your time and consideration. Looking forward to your response. Best regards, [Your Name] [Your Contact Information]"
`;
