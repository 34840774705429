import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisHorizontalIcon, PlusIcon } from "@heroicons/react/20/solid";
import { Modal } from "./CompanyModal";

const serverUrl = process.env.REACT_APP_SERVER_URL;

interface CompanyProfile {
  _id?: string;
  companyName: string;
  founders?: string;
  businessDeveloper?: string;
  whoUse?: string;
  industry?: string;
  website?: string;
  description?: string;
  foundedYear?: number;
  numberOfEmployees?: number;
  location?: string;
}

const CompanyProfilePage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [profiles, setProfiles] = useState<CompanyProfile[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<CompanyProfile | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchProfiles = async () => {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${serverUrl}/company-profiles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProfiles(response.data);
    };

    fetchProfiles();
  }, [getAccessTokenSilently]);

  const handleProfileClick = (profile: CompanyProfile) => {
    setSelectedProfile(profile);
    setIsModalOpen(true);
  };

  const handleNewProfileClick = () => {
    setSelectedProfile({
      companyName: "",
      founders: "",
      businessDeveloper: "",
      whoUse: "",
      industry: "",
      website: "",
      description: "",
      foundedYear: undefined,
      numberOfEmployees: undefined,
      location: "",
    });
    setIsModalOpen(true);
  };

  const handleSave = async (profile: CompanyProfile) => {
    const token = await getAccessTokenSilently();
    if (profile._id) {
      await axios.put(`${serverUrl}/company-profile/${profile._id}`, profile, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      await axios.post(`${serverUrl}/company-profile`, profile, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    setIsModalOpen(false);
    const updatedProfiles = await axios.get(`${serverUrl}/company-profiles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setProfiles(updatedProfiles.data);
  };

  return (
    <div>
      <ul
        role="list"
        className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
      >
        {profiles.map((profile) => (
          <li
            key={profile._id}
            className="overflow-hidden rounded-xl border border-gray-200"
          >
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
              <div className="text-sm font-medium leading-6 text-gray-900">
                {profile.companyName}
              </div>
              <Menu as="div" className="relative ml-auto">
                <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Open options</span>
                  <EllipsisHorizontalIcon
                    aria-hidden="true"
                    className="h-5 w-5"
                  />
                </MenuButton>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none"
                >
                  <MenuItem>
                    <button
                      onClick={() => handleProfileClick(profile)}
                      className="block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left"
                    >
                      Edit
                    </button>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Industry</dt>
                <dd className="text-gray-700">{profile.industry}</dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500">Founded Year</dt>
                <dd className="text-gray-700">{profile.foundedYear}</dd>
              </div>
            </dl>
          </li>
        ))}
        <li
          className="flex justify-center items-center cursor-pointer overflow-hidden rounded-xl border border-gray-200 bg-gray-50"
          onClick={handleNewProfileClick}
        >
          <PlusIcon className="h-12 w-12 text-gray-400" />
        </li>
      </ul>

      {isModalOpen && selectedProfile && (
        <Modal
          profile={selectedProfile}
          onSave={handleSave}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default CompanyProfilePage;
