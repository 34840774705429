import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import TokenHandler from "./components/TokenHandler"; // Import TokenHandler
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from "./components/ProtectedRoute"; // Import ProtectedRoute
import CompanyProfile from "./pages/CompanyProfile";

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <TokenHandler /> {/* Add TokenHandler component */}
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component={Dashboard} />}
          />
          <Route
            path="/profile"
            element={<ProtectedRoute component={Profile} />}
          />
          <Route
            path="/company-profile"
            element={<ProtectedRoute component={CompanyProfile} />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
