import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const GmailModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [gmailUsername, setGmailUsername] = useState(user?.gmailUsername || "");
  const [gmailPassword, setGmailPassword] = useState(user?.gmailPassword || "");
  const [message, setMessage] = useState("");

  const handleSaveGmail = async () => {
    try {
      const token = await getAccessTokenSilently();
      if (user && user.sub) {
        const dataToUpdate: any = {
          userId: user.sub,
          name: user.name,
          email: user.email,
        };

        if (gmailUsername) dataToUpdate.gmailUsername = gmailUsername;
        if (gmailPassword) dataToUpdate.gmailPassword = gmailPassword;

        const response = await axios.post(
          `${serverUrl}/update-profile`,
          dataToUpdate,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setMessage("Gmail credentials updated successfully!");
        onClose(); // Close the modal after saving
      } else {
        setMessage("Error: User data is missing.");
      }
    } catch (error) {
      setMessage("Error updating Gmail credentials.");
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Panel className="bg-white p-6 rounded shadow-xl max-w-lg w-full">
          <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
            Update Gmail Credentials
          </Dialog.Title>
          <div className="mt-4">
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Gmail E-mail Address
              </label>
              <input
                type="text"
                value={gmailUsername}
                onChange={(e) => setGmailUsername(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="example@gmail.com"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Gmail *App* Password
              </label>
              <input
                type="password"
                value={gmailPassword}
                onChange={(e) => setGmailPassword(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Your Gmail *APP* Password"
              />
            </div>
            {message && <p className="mt-4">{message}</p>}
          </div>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={handleSaveGmail}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Save
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default GmailModal;
