import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const TokenHandler = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const handleTokenExpiry = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: "https://dev-msaga5awta8j3ff2.us.auth0.com/api/v2/",
            scope: "read:current_user",
          },
        });
        setAccessToken(token);
      } catch (error) {
        console.error("Error refreshing access token:", error);
      }
    };

    if (isAuthenticated) {
      handleTokenExpiry();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  return null;
};

export default TokenHandler;
