import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Profile from "../components/Profile";

const Dashboard = () => {
  const { user } = useAuth0();

  return (
    <div>
      <div>
        <Profile />
      </div>
    </div>
  );
};

export default Dashboard;
