import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import auth0Config from "./auth/auth0-config";

const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <div>
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      authorizationParams={{
        redirect_uri: auth0Config.redirectUri,
        audience: "https://dev-msaga5awta8j3ff2.us.auth0.com/api/v2/",
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true} // Enable refresh tokens
      cacheLocation="localstorage" // Store tokens in local storage
    >
      <App />
    </Auth0Provider>
  </div>
);
