import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const AuthButtons = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  return (
    <div>
      {!isAuthenticated ? (
        <a
          className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
          href="#"
          onClick={() =>
            loginWithRedirect({
              appState: { returnTo: "/dashboard" },
            })
          }
        >
          Log In
        </a>
      ) : (
        <a
          className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
          href="#"
          onClick={() =>
            logout({
              logoutParams: { returnTo: window.location.origin },
            })
          }
        >
          Log Out
        </a>
      )}
    </div>
  );
};

export default AuthButtons;
