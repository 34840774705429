// src/helpers/reviewHelper.ts
import axios from "axios";
import { CSVData } from "./csvHelper";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export class ReviewHelper {
  static async reviewContent(
    row: CSVData,
    token: string
  ): Promise<{ reviewedContent: string } | null> {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!apiKey) {
      console.error("OpenAI API key not found.");
      return null;
    }

    try {
      const contentReviewPrompt = `I have an e-mail content. I want to make it ready to send. I want you to review the following email content and perform these actions:
      1- Check for grammatical errors and fix them
      2- If there anything with placeholder, remove them.
      3- If there anything ridiculous, delete or fix that.
      4- Delete the all qoutation marks or titles, marks etc. Make it ready to send. I will immadiately send this email so I will not review it. Make it ready to send.
      5- Delete any subjects. This is just the email content/body.
      6- Do not add any explanation or intro or anything.
      This is my email content: ${row["Email Content"]}`;

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4-turbo",
          messages: [
            {
              role: "system",
              content: "You are a helpful assistant.",
            },
            {
              role: "user",
              content: contentReviewPrompt,
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      const reviewedContent = response.data.choices[0].message.content;
      return { reviewedContent };
    } catch (error) {
      console.error("Error calling OpenAI API for content review:", error);
      return null;
    }
  }

  static async reviewTitle(
    row: CSVData,
    token: string
  ): Promise<{ reviewedTitle: string } | null> {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    if (!apiKey) {
      console.error("OpenAI API key not found.");
      return null;
    }

    try {
      const titleReviewPrompt = `I have an e-mail title. I want to make it ready to send. I want you to review the following email title and perform these actions:
      1- Check for grammatical errors and fix them
      2- If there anything with placeholder, remove them.
      3- Delete the all qoutation marks or titles, marks etc. Make it ready to send. I will immadiately send this email so I will not review it. Make it ready to send.
      4- Do not add any explanation or intro or anything.
      This is my email content: ${row["Email Title"]}`;

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4-turbo",
          messages: [
            {
              role: "system",
              content: "You are a helpful assistant.",
            },
            {
              role: "user",
              content: titleReviewPrompt,
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      const reviewedTitle = response.data.choices[0].message.content;
      return { reviewedTitle };
    } catch (error) {
      console.error("Error calling OpenAI API for title review:", error);
      return null;
    }
  }
}
