import React from "react";

interface CompanyProfile {
  _id?: string;
  companyName: string;
  founders?: string;
  businessDeveloper?: string;
  whoUse?: string;
  industry?: string;
  website?: string;
  description?: string;
  foundedYear?: number;
  numberOfEmployees?: number;
  location?: string;
}

interface ModalProps {
  profile: CompanyProfile;
  onSave: (profile: CompanyProfile) => void;
  onClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({ profile, onSave, onClose }) => {
  const [tempProfile, setTempProfile] = React.useState<CompanyProfile>(profile);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTempProfile({
      ...tempProfile,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    onSave(tempProfile);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-6">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg sm:max-w-md md:max-w-xl lg:max-w-2xl h-full sm:h-auto overflow-y-auto">
        <h2 className="text-lg font-medium leading-6 text-gray-900 mb-4">
          {profile._id ? "Edit Company Profile" : "Create New Company Profile"}
        </h2>
        <div className="space-y-4 sm:space-y-6">
          <div>
            <label
              htmlFor="companyName"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Company Name
            </label>
            <input
              id="companyName"
              name="companyName"
              type="text"
              value={tempProfile.companyName}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="founders"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Founders
            </label>
            <input
              id="founders"
              name="founders"
              type="text"
              value={tempProfile.founders}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="businessDeveloper"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Business Developer
            </label>
            <input
              id="businessDeveloper"
              name="businessDeveloper"
              type="text"
              value={tempProfile.businessDeveloper}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="whoUse"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Who Uses
            </label>
            <input
              id="whoUse"
              name="whoUse"
              type="text"
              value={tempProfile.whoUse}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="industry"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Industry
            </label>
            <input
              id="industry"
              name="industry"
              type="text"
              value={tempProfile.industry}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="website"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Website
            </label>
            <input
              id="website"
              name="website"
              type="text"
              value={tempProfile.website}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={tempProfile.description}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="foundedYear"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Founded Year
            </label>
            <input
              id="foundedYear"
              name="foundedYear"
              type="number"
              value={tempProfile.foundedYear || ""}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="numberOfEmployees"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Number of Employees
            </label>
            <input
              id="numberOfEmployees"
              name="numberOfEmployees"
              type="number"
              value={tempProfile.numberOfEmployees || ""}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="location"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Location
            </label>
            <input
              id="location"
              name="location"
              type="text"
              value={tempProfile.location}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            />
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
