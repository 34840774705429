// src/helpers/websiteHelper.ts
import axios from "axios";

export const fetchWebsiteMarkdown = async (url: string): Promise<string> => {
  try {
    const serverUrl = process.env.REACT_APP_SERVER_URL;

    // Add protocol if missing
    const normalizedUrl =
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `http://${url}`;

    const response = await axios.get(`${serverUrl}/fetch-website-markdown`, {
      params: { url: normalizedUrl },
    });
    return response.data.markdown;
  } catch (error) {
    console.error("Error fetching website content:", error);
    throw error;
  }
};
