import { CallToAction } from "../homepageComponents/CallToAction";
import { Faqs } from "../homepageComponents/Faqs";
import { Footer } from "../homepageComponents/Footer";
import { Header } from "../homepageComponents/Header";
import { Hero } from "../homepageComponents/Hero";
import { Pricing } from "../homepageComponents/Pricing";
import { PrimaryFeatures } from "../homepageComponents/PrimaryFeatures";
import { SecondaryFeatures } from "../homepageComponents/SecondaryFeatures";
import { Testimonials } from "../homepageComponents/Testimonials";

export default function Testing() {
  return (
    <>
      <Header />
      <main>
        <Hero />
        <PrimaryFeatures />
        <SecondaryFeatures />
        <CallToAction />
        <Testimonials />
        <Pricing />
        {/* <Faqs /> */}
      </main>
      <Footer />
    </>
  );
}
